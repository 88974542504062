// Set site info
// var siteinfo = document.getElementsByName("siteinfo")[0];
// var site_id = siteinfo.getAttribute("data-siteid");
// var lang = siteinfo.getAttribute("data-sitelang");

$(document).ready(function ()
{
	/*
	 *	FILTER
	 */

	$('input[name="type"]').on('change', typeChanged);

	function typeChanged(e) {
		var type = $(e.currentTarget).attr('value');

		if (type == 'particulier') {
			$('.filter__afval').show();
			$('.filter__procat').hide();

		} else if (type == 'professioneel') {
			$('.filter__afval').hide();
			$('.filter__procat').show();
		}
	}

	$('[data-popup="close"]').on('click', (evt) => {
		evt.preventDefault();
		
		var $wrapper = $(evt.target).closest('.popup-wrapper');
		$wrapper.fadeOut(() => {
			$wrapper.remove();
		});

		return false;
	});



	/*
	 *	RICH-CONTENT
	 */

	// Lightbox
	$('.lightbox').fancybox({
		overlayOpacity: 0.8,
		overlayColor: 'black',
		padding: 0,
		titleShow: true
	});
	$('.lightbox-media').fancybox({
		overlayOpacity: 0.8,
		overlayColor: 'black',
		padding: 0,
		titleShow: true,
		helpers : {
			media : {}
		}
	});

	// Youtube/Vimeo thumbs
	$(".rc-video__thumb").not('.lightbox-media').click( startVideo );
	$(".rc-video__overlay").not('.lightbox-media').click( startVideo );

	function startVideo(e) {
		var block = $(e.currentTarget);
		var iframe = $('iframe', block.parent());
		var src = iframe.attr('src');

		if (src.indexOf('autoplay=0') > -1) {
			iframe.attr('src', src.replace('autoplay=0','autoplay=1') );
		} else {
			if( src.indexOf('?') > -1 )
			{
				iframe.attr('src', src + '&autoplay=1');
			}
			else
			{
				iframe.attr('src', src + '?autoplay=1');
			}
		}

		$('.rc-video__overlay', block.parent()).fadeOut(150);
		$('.rc-video__thumb', block.parent()).fadeOut(150);
	}

	// Contentblocks slider
	$('.rc-article-slider').slick({
		adaptiveHeight: true,
		prevArrow: '<button type="button" class="slick-prev"><i class="icon-angle-left"></i></button>',
		nextArrow: '<button type="button" class="slick-next"><i class="icon-angle-right"></i></button>',
	});

	// Quote slider
	$('.rc-quote-slider__slick').slick({
		adaptiveHeight: true,
		prevArrow: '<button type="button" class="slick-prev"><i class="icon-angle-left"></i></button>',
		nextArrow: '<button type="button" class="slick-next"><i class="icon-angle-right"></i></button>',
	});

	$('.file-upload').change(function() {
	  readURL(this);
	});
});

function readURL(input) {
	var html = '<ul>';
	if (input.files && input.files[0]) {
		for (var i = input.files.length - 1; i >= 0; i--) {
		    var file = input.files[i];
		    html += '<li>'+file.name+'</li>';
		   	console.log(file);
		}
	}
	html += '</ul>';
	$('+ .files_list', input).html(html);
}

$(window).on('load', function ()
{
	// Image slider (options in data-slick)
	$('.rc-image-slider__slick').slick();
});



// CAPTCHA
var captcha_validated = false;

function onSubmitreCAPTCHA(response)
{
	captcha_validated = true;
	$('.rc-form form').submit();
}

function validateForm(event)
{
	event.preventDefault();
	var form = this;

	var validator = $(form).data('bs.validator');
	validator.validate();

	if (captcha_validated && !validator.hasErrors()) {
		form.submit();
	} else if(!captcha_validated) {
		grecaptcha.reset();
		grecaptcha.execute();
	}
}